import { getAuthState } from "@sparky/framework";

export const getSecondLevelPagesMetadata = (translations) => {
    return {
        USERS: {
            pageHeader: translations?.appUsers || "APPLICATION USERS",
            dataLabel: translations?.categories || "Categories",
        },
        MODELS: { pageHeader: translations?.aiModels || "AI MODELS", dataLabel: translations?.models || "Models" },
        INTERNET: {
            pageHeader: translations?.internetDestinations || "INTERNET DESTINATIONS",
            dataLabel: translations?.categories || "Categories",
        },
    };
};

export const getLSRegion = (): string => getAuthState()?.instances?.get("logging_service")?.region ?? "americas";
export const getPARegion = (): string => getAuthState()?.instances?.get("prisma_access")?.region ?? "americas";
