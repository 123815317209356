/* eslint-disable @typescript-eslint/no-var-requires */

import { getFrameworkVars } from "@sparky/framework";
import { isAvailable } from "../utils/isAvailable"

export function getTsgState() {
    const { pdf_mode } = getFrameworkVars();
    const isPDF = Boolean(pdf_mode);
    import("../hooks/spiffy/useSlrAvailable")
        .then((module) => {
            return module.fetchSlrAvailable();
        })
        .then((slrAvailable) => {
            console.info("SLR availability status:", slrAvailable);
        });
    return {
        isAvailable,
        isPDF,
    };
}