import { getAuthState } from "@sparky/framework";
import {
    PDF_REPORTS_EXT_POINT,
    type TPDFReportTypeExtensionContributions,
} from "@ui-platform/ui-core-services-extension-points";
import { getLSRegion, getPARegion } from "constants/Constants";

export default {
    point: PDF_REPORTS_EXT_POINT,
    configs: [
        {
            id: "airs.slr",
            config: {
                name: "SLR Report",
                description: "SLR Report....",
                report_type: "airs.slr",
                category: "summary",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                isAllowed: (state: any) => Boolean(state.airs?.slrAvailable),
                params: () => ({
                    timeInterval: "7d",
                    email: getAuthState().user.email,
                    cdlRegion: getLSRegion(),
                    paRegion: getPARegion(),
                }),
                ScheduleForm: () => import("../components/views/ScheduleForm"),
                version: 2,
            },
        },
    ],
} satisfies TPDFReportTypeExtensionContributions;
